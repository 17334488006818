import { initializeApp } from 'firebase/app';
import {getDatabase} from 'firebase/database';
import { GoogleAuthProvider, signInWithPopup, getAuth, createUserWithEmailAndPassword  } from "firebase/auth";

import { firebaseConfig } from 'constants/defaultValues';
import { NotificationManager } from "../components/common/react-notifications";



const app =  initializeApp(firebaseConfig);

const auth = getAuth(app)
const database = getDatabase(app)

const googleAuthProvider = new GoogleAuthProvider();


const handleGoogleSignIn = async () => {
  try {
    // eslint-disable-next-line no-unused-vars
    const result = await signInWithPopup(auth, googleAuthProvider);
    // const user = result.user;
    NotificationManager.success(
      `Thank you for joining the movement. We’ll be in touch soon with the next steps. `,
      'Success!',
      3000,
      null,
      null
    );

  } catch (error) {
    console.log(error)
    NotificationManager.error(
      error.message,
      'Something went wrong',
      3000,
      null,
      null
    );
  }
};

export { auth, database, handleGoogleSignIn , createUserWithEmailAndPassword };

/*

import { useEffect } from "react";

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in
    } else {
      // User is signed out
    }
  });

  return () => unsubscribe(); // Clean up the listener when the component unmounts
}, []);
*/

